import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const styles = theme => ({
  footer: {
    padding: theme.spacing.unit * 2,
  }
})

class Footer extends Component {

  render() {
    const { classes } = this.props;

    return <footer className={classes.footer}>
      <Grid container >
        <Grid item md>
          <Typography variant="caption">
            {this.props.textSecondary|| 'تطبيق محتوى زيدو'}
          </Typography>
        </Grid>
        <Grid item md >
          <Typography variant="caption" align="right">
            {this.props.text}
          </Typography>
        </Grid>
      </Grid>


    </footer>;
  }
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);
