import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    
});

class StepSelect extends Component {



  // componentWillReceiveProps(nextProps){
  //   if(nextProps.value!== this.props.value){
  //     this.setState({value: nextProps.value });
  //   }
  // }

  render() {
    const {steps, label, handleSelect, name, value} = this.props;

    return <TextField
          select
          name={name}
          label={label}
          value={value}
          onChange={handleSelect(name)}
          SelectProps={{
            MenuProps: {
            //  className: classes.menu,
            },
          }}
          margin="normal"
          variant="filled"
          fullWidth
          disabled={steps && steps.length === 0}
        >
          <MenuItem value="" ></MenuItem>
        {steps.map((option,i) => (
          <MenuItem key={i} value={option._id}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>;
  }
}

StepSelect.propTypes = {
  steps: PropTypes.array,
};

export default withStyles(styles)(StepSelect);