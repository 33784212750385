import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
 import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/AddBox';
import MoreHIcon from '@material-ui/icons/MoreHoriz';
import NotifyIcon from '@material-ui/icons/AddAlert';
import { lighten } from '@material-ui/core/styles/colorManipulator';

const toolbarStyles = theme => ({
    root: {
      paddingRight: theme.spacing.unit,
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    spacer: {
      flex: '1 1 100%',
    },
    actions: {
      color: theme.palette.text.primary,
      display: 'flex',
    },
    title: {
      flex: '0 0 auto',
    },
  });
  
  let EnhancedTableToolbar = props => {
    const { numSelected, classes, tableTitle, handleAdd, handleEdit, handleDelete, handleDetails, handleNotify } = props;

  return (
      <Toolbar
        className={classNames(classes.root, {
       //   [classes.highlight]: numSelected > 0,
        })}
      >
        <div className={classes.title}>
          {/* {numSelected > 0 ? (
            <Typography color="inherit" variant="subtitle1">
              {numSelected} selected
            </Typography>
          ) : ( */}
            <Typography variant="h6" color="primary" id="tableTitle">
              {tableTitle}
            </Typography>
          {/* )} */}
        </div>
        <div className={classes.spacer} />
        <div className={classes.actions}>
          {numSelected > 0 ? (
            <div className={classes.actions}>
              {
                handleDelete !== undefined &&
                <Tooltip title="Delete">
                  <IconButton aria-label="Delete" onClick={ handleDelete }>
                    <DeleteIcon fontSize="small" color='primary' />
                  </IconButton>
                </Tooltip>
              }
              {
                handleEdit !== undefined &&
                <Tooltip title="Edit">
                  <IconButton aria-label="Edit" onClick={ handleEdit }>
                    <EditIcon fontSize="small" color='primary'/>
                  </IconButton>
                </Tooltip>
              }
              {
                handleDetails !== undefined &&
                <Tooltip title="Details">
                    <IconButton aria-label="Details" onClick={ handleDetails }>
                      <MoreHIcon fontSize="small" color='primary'/>
                    </IconButton>
                </Tooltip>
              }
              {
                handleNotify !== undefined &&
                <Tooltip title="Notify">
                    <IconButton aria-label="Notify" onClick={ handleNotify }>
                      <NotifyIcon fontSize="small" color='primary'/>
                    </IconButton>
                </Tooltip>
              }
            </div>
          ) : ( handleAdd !== undefined &&
            <Tooltip title="Add" onClick={ handleAdd }>
              <IconButton aria-label="Add">
                <AddIcon fontSize="small" color='primary'/>
              </IconButton>
            </Tooltip>
          )}
        </div>
      </Toolbar>
    );
  };
  
  EnhancedTableToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    tableTitle: PropTypes.string,
  };
  
export default withStyles(toolbarStyles)(EnhancedTableToolbar);