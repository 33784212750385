import client from './Api';

const Helper = {
    logIn: (credentials) => {
        let payload;
        return new Promise( (res, rej) => {
            if(!credentials) {
                // Try to authenticate using the JWT from localStorage
                payload = Object.assign({ strategy: 'jwt' });
              } else {
                // If we get login information, add the strategy we want to use for login
                payload = Object.assign({ strategy: 'local' }, credentials);
            }
    
            client.authenticate(payload).then(() => {
                res(true);
              }).catch(() => {
                res(false);
              })
        })        
    },
    logOut: () => {
        client.logout();
    },
    isAuthenticated: () => {
        const token = window.localStorage.getItem('feathers-jwt');
        return token !== null;
    },
}

export default Helper;