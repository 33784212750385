import React, { Component, Fragment } from 'react';
import Layout from '../components/layout';
 import withRoot from '../withRoot';
import ContentHeader from '../components/ContentHeader';
import ProcessTable from '../components/ProcessTable';
import AlertDialog from '../components/AlertDialog';

import client from '../utils/Api'

const rows = [
	{ id: 'process', numeric: false, disablePadding: true, label: 'اسم المرحلة' },
	{ id: 'allSteps', numeric: false, disablePadding: false, label: 'مراحل الإعداد' },
	//{ id: 'actions', numeric: false, disablePadding: false, label: '' },
];

const processesService = client.service('processes');

class Home extends Component {

	// initialize our state 
	state = {
		isLoading: true,
		processes: [],
		checkedItems: [],
		openAlertDialog: false,
	}
	loadTemplates() {
			// Get all processes
			Promise.all([
				processesService.find(),
			]).then( ([responseJson]) => {
				let processes = []
				processes = responseJson.data.map( process => {
					process.process = process.name
					if (typeof process.steps !== undefined) {
						const steps = process.steps;
						process.allSteps = steps.map( step =>step.name.name).join(', ') 
					}
					//processes.push( process );
					return process
				});
		
				// update the state
				this.setState({ processes: processes,  isLoading: false});
			}).catch( err => {client.logout();});
	}

	componentWillMount() {
		this.loadTemplates();
	}

	handleEdit = event =>{
		console.log('handleEdit', event)
	}

	handleDelete = (e,_ids) =>	{
		this.setState({ openAlertDialog: true, checkedItems: _ids })
	}
	
	handleModalClose = () => {
		this.setState({ openAlertDialog: false })
	}

	handleModalConfirmation = () => {
		this.setState({ openAlertDialog: false })
		const {checkedItems} = this.state
		return checkedItems.map( id => {
			processesService.remove(id, { query: {} })
			.then((responseJson) => {
				if ( responseJson.name === "NotFound" ) 
					console.log ( responseJson.message)
				else {
					this.loadTemplates()
				}
			})
			return null;
		})
		
	}

	handleEdit = (e,_id) =>	{
		window.location='/processes/'+_id;
	}

	render() {
		const {processes,openAlertDialog, isLoading} = this.state;
		return (
			<Fragment>
				<Layout
					activeTab="2"
					title={'محتوى زيدو'}
					footerText={'جميع الحقوق محفوظة لمجموعة عالم زيدو 2019'}
					isLoading= {isLoading}
				>
					<ContentHeader
						title=' قـائمة عمليات الإعداد'
						desc='هي قائمة توضح الأنواع المراحل وتسلسلها التي يتم أجرائها على محتوى عالم زيدو'
						showBackLink={false}
					/>
					<AlertDialog 
						open={openAlertDialog} 
						handleClose= {this.handleModalClose} 
						handleConfirmation={this.handleModalConfirmation}
					/>
					<ProcessTable 
						processes={processes} 
						rows={rows} 
						tableTitle={'عمليات الإعداد ومراحلها'}
						actions= {
							{
								handleAdd: () => window.location='/processes/add',
								handleEdit: this.handleEdit,
								handleDelete: this.handleDelete,
							}
						}
					/>
				</Layout>
			</Fragment>
		);
	}
}

export default withRoot(Home);

