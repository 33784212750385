import React, { Component, Fragment } from 'react';
import { CssBaseline, AppBar, Toolbar, IconButton, Typography, LinearProgress } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import LogoutIcon from '@material-ui/icons/SettingsPower';
import { withStyles } from '@material-ui/core/styles';
import withRoot from '../../withRoot';
import { drawerWidth } from '../MenuDrawer';
import PropTypes from 'prop-types';
import Helper from '../../utils/Helper'
import { withRouter } from "react-router";


const styles = theme => ({
	appBar: {
		marginLeft: drawerWidth,
		[theme.breakpoints.up('md')]: {
		//	width: `calc(100% - ${drawerWidth}px)`,
		},
	},
	menuButton: {
		marginRight: 20,
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	grow: {
		flexGrow: 1,
	},
	label: {
		fontWeight : 500,
		fontSize: '1.25rem',
		fontFamily: 'Tajawal, sans-serif',
	}
});

class Header extends Component {
	state = {
		activeTab: this.props.activeTab || "1",
	}

	logOut = () => {
		Helper.logOut();
		const { history } = this.props;
		history.push('/login')
	}

	handleChange = (event, activeTab) => {
		window.location= activeTab === "1" ? '/':'/processes'
	};

	render() {
		let { classes, title, isLoading } = this.props;
		let { activeTab } = this.state;
		return (
			<Fragment>
				<CssBaseline />
				<AppBar position="fixed" className={classes.appBar}>
					<Toolbar variant='dense'>
						{/* <IconButton
							color="inherit"
							aria-label="Open drawer"
							onClick={handleDrawerToggle}
							className={classes.menuButton}
						>
							<MenuIcon />
						</IconButton> */}
						<Typography variant="h6" color="inherit" noWrap>
							{title}
						</Typography>
						<Tabs
							value={activeTab} 
							onChange={this.handleChange}
							indicatorColor="secondary"
							centered
							className={classes.grow}
						>
							<Tab value="1" label="وحدات المحتوى " className={classes.label}/>
							<Tab value="2" label="مراحل الاعداد " className={classes.label}/>
						</Tabs>
						<IconButton color="inherit" onClick={this.logOut}>
							<LogoutIcon />
						</IconButton>
					</Toolbar>
					{
						isLoading &&
						<LinearProgress color="primary" />
					}
				</AppBar>
			</Fragment>
		);
	}
}


Header.propTypes = {
	classes: PropTypes.object.isRequired,
	// Injected by the documentation to work in an iframe.
	// You won't need it on your project.
	container: PropTypes.object,
	theme: PropTypes.object.isRequired,
};


Header = withRouter(Header);

export default withRoot(withStyles(styles, { withTheme: true })(Header));