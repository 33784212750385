import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import withRoot from '../../withRoot';
import { CssBaseline, Grid } from '@material-ui/core';
import Header from './Header';
import Footer from '../Footer';

//import SideBar from './SideBar';

const styles = theme => ({
	root: {
		display: 'flex',
	},
	toolbar: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		paddingLeft: theme.spacing.unit * 3,
		paddingRight: theme.spacing.unit * 3,

	},
});

class Layout extends React.Component {
	state = {
		mobileOpen: false,
	};

	handleDrawerToggle = () => {
		this.setState(state => ({ mobileOpen: !state.mobileOpen }));
	};

	render() {
		const { classes, title, footerText, activeTab, isLoading } = this.props;

		return (
			<div className={classes.root}>
				<CssBaseline />
				<Header
					title={title}
					handleDrawerToggle={this.handleDrawerToggle}
					activeTab={activeTab}
					isLoading={isLoading}
				/>
				{/* <SideBar 
					handleDrawerToggle={this.handleDrawerToggle}
					mobileOpen={this.state.mobileOpen}
					activeItem={activeItem}
				/> */}
				<Grid container direction='column'>
					<Grid item>
						<main className={classes.content}>
							<div className={classes.toolbar} />
							{/* this is the main content of the page */}
							{this.props.children}
						</main>
					</Grid>
					<Grid item>
						<Footer text={footerText} />
					</Grid>
				</Grid>
			</div>
		);
	}
}

Layout.propTypes = {
	classes: PropTypes.object.isRequired,
	// Injected by the documentation to work in an iframe.
	// You won't need it on your project.
	container: PropTypes.object,
	theme: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles, { withTheme: true })(Layout));
