import React from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { Button, Typography, } from '@material-ui/core';
 import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
    successBtn: {
      backgroundColor: '#2BCCA8',
    },
    failBtn: {
        backgroundColor: '#F55046',
        margin: theme.spacing.unit * 2,
    },
  })

class NotificationDialog extends React.Component {
  state = {
    open: this.props.open,
    selectedDate: new Date('2014-08-18T21:11:54'),
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  handleSelect = () => {

  }
  handleChange = (a) => {
    console.log(a)
  }
  render() {
    const {step='المراجعة اللغوية للنص', state=' قيد التنفيذ' , level='المستوى الثالث', content='الكتاب الأول'} = this.props

    return (
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth='md'
          scroll="body"
        >
          <DialogTitle id="form-dialog-title"> تفاصيل {step} 
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
                {content} | {level} | الحالة -{state} 
            </DialogContentText>
            <Grid container  spacing={16}>
              <TextField
                  id="notes"
                  value='لم يتم الانتهاء من عملية الترجمة بشكل كامل وتم تأجيل الموعد حتى الشهر القادم
                  ويجب مراعاة ذلك عند وضع التوقيتات للمراحل القادمة بإذن الله'
                  label={'ملاحظات'}
                  onChange={this.handleChange('notes')}
                  margin="normal"
                  variant="filled"
                  fullWidth
                  multiline
                  rowsMax="4"
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid item xs={12}>
                <Typography align="right">
                    <Button variant="text" size="large" color="primary"  onClick={this.handleClose}
                    > إلغاء التنبيه</Button>
                    <Button variant="contained" size="large" color="primary"  
                    onClick={ this.handleClose }
                    > أرسال التنبيه</Button>
                </Typography>
            </Grid>
          </DialogActions>
        </Dialog>
    );
  }
}

export default withStyles(styles)(NotificationDialog)