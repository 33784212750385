import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import withRoot from '../withRoot';
import { LinearProgress } from '@material-ui/core';
import { Redirect } from 'react-router'
import ErrorIcon from '@material-ui/icons/Error'
import Helper from '../utils/Helper';

const styles = theme => {
  return {
    main: {
      width: 'auto',
      display: 'block', // Fix IE 11 issue.
      marginLeft: theme.spacing.unit * 3,
      marginRight: theme.spacing.unit * 3,
      [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
        width: 400,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing.unit * 8,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
      margin: theme.spacing.unit,
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing.unit,
    },
    submit: {
      marginTop: theme.spacing.unit * 3,
    },
  }
};

class SignIn extends React.Component {
  state = {
    email: '',
    password: '',
    isLoading: false,
    isAuthenticated: Helper.isAuthenticated()
  }

  handleChange = (event) => {
    let { name, value } = event.target;
    let newState = {};
    newState[name] = value;
    this.setState(newState);
  }

  doLogin = async (event) => {
    event.preventDefault();
    // start loading mask
    this.setState({ isLoading: true, error:null });

    const { email, password } = this.state
    let credentials = { email, password};
    // login
    let isAuthenticated = await Helper.logIn(credentials);
    if ( isAuthenticated ) {
      this.setState({ isLoading: false, isAuthenticated})
    } else {
      this.setState({ error: "Invalid login" })
      this.setState({ isLoading: false, isAuthenticated})      
    }
  }

  getFromPath = () => {
    const { state } = this.props.location
    if (!state || !state.from) {
      return '/'
    }
    return state.from.pathname
  }


  render() {
    const { classes } = this.props;
    if (this.state.isAuthenticated) return <Redirect to={this.getFromPath()} />

    return (
      <main className={classes.main}>
        <Paper className={classes.paper}>

          <Avatar className={classes.avatar}>
            <LockIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            تسجيل الدخول
          </Typography>
          <form className={classes.form} onSubmit={this.doLogin}>
            {
              this.state.isLoading &&
              <LinearProgress />
            }

            {
              this.state.error &&
              <Typography color="error" variant="caption" style={{ display: 'flex', alignItems: 'center' }}>
                <ErrorIcon fontSize="small" />
                {this.state.error}
              </Typography>
            }

            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="email">البريد الالكتروني</InputLabel>
              <Input id="email" name="email" autoComplete="email" autoFocus
                onChange={this.handleChange}
                disabled={this.state.isLoading} />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">كلمة المرور</InputLabel>
              <Input name="password" type="password" id="password"
                autoComplete="current-password"
                onChange={this.handleChange}
                disabled={this.state.isLoading} />
            </FormControl>
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={this.state.isLoading}
            >
              دخول
            </Button>
          </form>
        </Paper>
      </main>
    );
  }
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(SignIn));
