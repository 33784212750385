import React, { Component, Fragment } from 'react';
import { Button, } from '@material-ui/core';
import withRoot from '../withRoot';
import ProcessTable from '../components/ProcessTable';
import { withStyles } from '@material-ui/core/styles';
import StepSelect from '../components/StepSelect';
import Grid from '@material-ui/core/Grid';


const styles = theme => ({
	textField: {
		padding: theme.spacing.unit ,
		marginTop: theme.spacing.unit * 1,
	},
	button: {
	  marginTop: theme.spacing.unit * 2,
	  padding: theme.spacing.unit * 2,
	},
});

class ProcessesManagement extends Component {

  // initialize our state 
  state = {
    //addStep : this.props.addStep,

  }

  render() {
    const { processes, rows, name, steps= [], step= [], classes,
            handleAdd, handleEdit, handleDelete, handleDetails, handleSelect,
            addStep, handleAddEditStep} = this.props;
  //  const { addStep  } = this.state;
    const _AddStep = addStep || processes.length  === 0

    return (
      <Fragment>
        { processes.length !== 0 && 
            <ProcessTable 
                processes={processes} 
                rows={rows} 
                tableTitle={' مراحل عملية ' +name}
                actions= {
                    {
                        handleAdd: handleAdd,
                        handleEdit: handleEdit,
                        handleDelete: handleDelete,
                        handleDetails: handleDetails,
                    }
                }
            />
        }
        <Grid item xs>
            <Grid container spacing={16} >
            { _AddStep && steps.length !== 0 && step.length !== 0 &&
                <>
                <Grid item xs>
                    { <StepSelect steps={steps} name={'name'}  value={step.name._id|| ''} label={'اسم المرحلة'}  handleSelect={ handleSelect} /> }                </Grid>
                <Grid item xs>
                    <StepSelect steps={steps} name={'order'} value={step.order._id|| ''} label={'ترتيب المرحلة'} handleSelect={ handleSelect} />
                </Grid>
                <Grid item xs>
                    {<StepSelect steps={steps} name={'parallel'} value={step.parallel._id|| ''} label={'المرحلة الموازية'} handleSelect={ handleSelect} />}
                </Grid>
                <Grid item xs>
                    {<StepSelect steps={steps} name={'onFailure'} value={step.onFailure._id|| ''} label={'عند الفشل نعود لمرحلة'} handleSelect={ handleSelect} />}
                </Grid>
                <Grid item xs>
                    <Button variant="contained" size="large" color="primary" fullWidth className={classes.button}
                    onClick={ handleAddEditStep  }
                    disabled= {step.name && step.name.name === "--"}
                    >
                    {(step._id === "") ? "إضافة":"تعديل"}
                    </Button>
                </Grid>
                </>
                }
                <Grid item xs={12}>
                {/* <Typography align="right">
                    <Button variant="text" size="large" color="primary"  className={classes.button}
                    onClick={this.handleCancel}
                    > إلغاء التعديلات </Button>
                    <Button variant="contained" size="large" color="primary"  className={classes.button}
                    onClick={ (match.params.id ) ? this.handleEditProcess: this.handleAddProcess}
                    disabled= { (processSteps && processSteps.length === 0) || name === ""}
                    > حفظ التعديلات </Button>
                </Typography> */}
                </Grid>
            </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(styles)(withRoot(ProcessesManagement));

