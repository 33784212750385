import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import rtl from 'jss-rtl';
import {JssProvider, jss} from 'react-jss';
import { createGenerateClassName, jssPreset } from '@material-ui/core/styles';

// Configure JSS
jss.setup({
    plugins: [...jssPreset().plugins, rtl()]
})

const generateClassName = createGenerateClassName();

// A theme with custom primary and secondary color.
// It's optional.
const theme = createMuiTheme({
  direction: 'rtl',
  useNextVariants: true,
  palette: {
    primary: {
      light: '#69c8ff',
      main: '#0a96eb',
      dark: "#0073b8"
    },
    secondary: {
      light: '#d2faf0',
      main: '#28cdaa',
      dark: "#12a184"
    },
    error: {
      light: '#ffe6dc',
      main: '#f44336',
      dark: "#d32f2f"
    },
    background: {
      default: '#f0f0f2',
    }
  },
  typography: {
    fontFamily: "'Tajawal', sans-serif",
    useNextVariants: true,
  },
});

function withRoot(Component) {
  function WithRoot(props) {
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    return (
      <MuiThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <JssProvider jss={jss} generateClassName={generateClassName}>
          <Component {...props} />
        </JssProvider>
      </MuiThemeProvider>
    );
  }

  return WithRoot;
}

export default withRoot;
