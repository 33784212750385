import React, { Component, Fragment } from 'react';
import Layout from '../components/layout';
import { Button, Typography, } from '@material-ui/core';
import withRoot from '../withRoot';
import ContentHeader from '../components/ContentHeader';
import ProcessTable from '../components/ProcessTable';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import StepSelect from '../components/StepSelect';
import Grid from '@material-ui/core/Grid';
import AlertDialog from '../components/AlertDialog';
import client from '../utils/Api'

const rows = [
  { id: 'name', numeric: false, disablePadding: true, label: 'المراحل' },
  { id: 'order', numeric: false, disablePadding: false, label:'ترتيب المرحلة'},
  { id: 'parallel', numeric: false, disablePadding: false, label: 'المراحل الموازية' },
  { id: 'onFailure', numeric: false, disablePadding: false, label: 'في حالة فشل نذهب لمرحلة' },
];

const styles = theme => ({
  textField: {
    padding: theme.spacing.unit,
  },
  button: {
    marginTop: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 2,
  },
});

const processesService = client.service('processes');
const stepsService = client.service('steps');


class Processes extends Component {

  // initialize our state 
  state = {
    name: '',
    isLoading: true,
    steps: [],
    processes: [],
    processSteps: [],
    checkedItems: [],
    addStep : true,
    step: {
      _id:'',
      name: { _id:'', name: '--'},
      parallel: { _id:'', name: '--'},
      order: { _id:'', name: ''},
      onFailure: { _id:'', name: '--'},  
    },
    openAlertDialog: false,
    alertDialog: {title:"",desc:""}
  }

  loadSteps() {
			// Get all steps
			Promise.all([
				stepsService.find(),
			]).then( ([responseJson]) => {
        console.log(responseJson)
				if (responseJson.code !== 500) {
         // let _steps =  //.map(step => { return { name: step.name, id: step._id } })
          this.setState({ steps: responseJson });
        }
			});
  }

  loadProcess(id) {
    
    Promise.all([
      processesService.get(id, {})
    ]).then( ([process]) => {
      if (process.code !== 500)
        this.setState({ 
          _id: process._id,
          name: process.name,
          isLoading: false,
          processSteps: process.steps.map((step, index) => { 
            return { name: step.step, ...step, _id:index,} 
          })
        });
    });
		 
	}

  async componentWillMount() {
    this.loadSteps();
  }

  componentDidMount() {
    const {match} = this.props
    console.log(match.params)
    if(match.params.id !== undefined)
      this.loadProcess(match.params.id)
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleSelect = item => event => {
    const {step, steps} = this.state
    let _step = { _id:'', name: '--'}

    if (event.target.value !== "")
      _step = steps.find(s => s._id === event.target.value)
    
    if ( item === 'order') {
      _step = this.getStepSelect().find(s => s._id === event.target.value)||_step
    }
    console.log('_step',item)
    this.setState({
      step: {...step, [item]: _step ,}
    });
  }

  handleCancel = () => {
    window.location='/processes'
  };

  handleModalClose = () => {
		this.setState({ openAlertDialog: false })
  }
  
  getStepOrderNotInSteps = ( ps ) => {
    let stepOrderNotInSteps = [];
    const steps_ids = ps.map(s => s.name._id)
    if ( ps ) {
      stepOrderNotInSteps = ps.filter((so) => so.order._id !== "" && !steps_ids.includes(so.order._id))
                              .map(s => s.order)    
    }

    return stepOrderNotInSteps 
  }
  // process
  handleAddProcess = () => {
    const {name, processSteps} = this.state
    const stepOrderNotInSteps = this.getStepOrderNotInSteps(processSteps)
    if(stepOrderNotInSteps.length !== 0 ){
      this.setState({ 
        openAlertDialog: true,
        alertDialog: {
          title:"مرحلة مفقودة ",
          desc:"المرجو إضافة المرحلة التالية: " + stepOrderNotInSteps.shift().name
        } })
    } else {
      Promise.all([
        processesService.create(  {name: name , steps: processSteps||[]} , {})
      ]).then( ([process]) => {
        console.log("process added ");
        this.setState(
          { name: '', processSteps:[]},
        );
        window.location='/processes/'
      });
    }
  };
  handleEditProcess = () => {
    const {_id, name, processSteps} = this.state
    const stepOrderNotInSteps = this.getStepOrderNotInSteps(processSteps)
    if(stepOrderNotInSteps.length !== 0 ){
      this.setState({ 
        openAlertDialog: true,
        alertDialog: {
          title:"مرحلة مفقودة ",
          desc:"المرجو إضافة المرحلة التالية: " + stepOrderNotInSteps.shift().name
        } })
    } else {
      processesService.update(_id, { name: name , steps: processSteps}, {}).then((responseJson) => {
        console.log("process edited ")
        window.location='/processes'
      })
    }
  };

  //step
  handleDeleteStep = (e,id) => {
    
    const {processSteps} = this.state
    let newSteps = []
    newSteps = processSteps.filter(step => step._id !== id[id.length -1])
    this.setState(
      { processSteps: newSteps},
    );
  }
  handleLoadEditedStep = (e, id) => {
    //TODO FIX async setState issue field not updated 
    const { processSteps } = this.state
    const _step = processSteps.filter((ps) => ps._id === id[0] ).shift()

    this.setState({
      step : _step
    });
  }

  handleAddEditStep = () => {
    let {  step, processSteps } = this.state;
    let order = processSteps.length+1;
    
// debugger
    console.log(step)
    if (step._id === ""){
      order = step.order.name.split('-').shift()
      processSteps.splice(order, 0, step);
    }
    else {
      order = step.order.name.split('-').shift()
      processSteps[step._id] = step;
      processSteps.splice(order-1, 0, processSteps.splice(step._id, 1)[0]);
    }
    this.setState({
      processSteps: processSteps,
      step : {
        _id:'',
        name: { _id:'', name: '--'},
        parallel: { _id:'', name: '--'},
        order: { _id:'', name: '--'},
        onFailure: { _id:'', name: '--'},  
      }
    }); 
  };

  // get steps for order and parallel and onFailure case
  getStepSelect () {
    const  {  processSteps } = this.state;
    return processSteps.map( (ps,i) => {return {_id:`${ps.name._id}`, name: `${++i}- ${ps.name.name}` }})
  }

  render() {
    const { classes, match } = this.props;
    const { name, step, steps, processSteps,  addStep, isLoading, openAlertDialog, alertDialog } = this.state;
    const _AddStep = addStep || processSteps.length  === 0
    return (
      <Fragment>
        <Layout
          activeTab="2"
          title={'محتوى زيدو'}
          footerText={'جميع الحقوق محفوظة لمجموعة عالم زيدو 2019'}
          isLoading= {isLoading}
        >
          <ContentHeader
            title=' إضافة و تعديل مراحل الإعداد'
            desc='لإعادة صياغة المراحل داخل كل عملية إعداد أو إضافة مراحل جديدة'
          />
          <AlertDialog 
						open={openAlertDialog} 
            handleClose= {this.handleModalClose}
            handleConfirmation={this.handleModalClose}
            title= {alertDialog.title}
            desc={alertDialog.desc}
					/>
          <Grid container >
            <Grid item xs={12}>
              <TextField
                id="filled-name"
                label="اسم عملية الإعداد"
                className={classes.textField}
                value={name}
                onChange={this.handleChange('name')}
                margin="normal"
                variant="filled"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              { 
                processSteps && processSteps.length  !== 0 && 
                <ProcessTable
                  processes={processSteps}
                  rows={rows}
                  tableTitle={'مراحل عملية الإعداد'}
                  actions= {
                    {
                      handleAdd: () => this.setState({ addStep: true}),
                      handleEdit: this.handleLoadEditedStep,
                      handleDelete:  this.handleDeleteStep,
                    }
                  }
                />
              }
            </Grid>
            <Grid item xs>
              <Grid container spacing={16} >
                { _AddStep && 
                <>
                  <Grid item xs>
                    <StepSelect steps={steps} name={'name'}  value={step.name._id|| ''} label={'اسم المرحلة'}  handleSelect={this.handleSelect} />
                  </Grid>
                  <Grid item xs>
                    <StepSelect steps={this.getStepSelect()} name={'order'} value={step.order._id|| ''} label={'ترتيب المرحلة'} handleSelect={this.handleSelect} />
                  </Grid>
                  <Grid item xs>
                    <StepSelect steps={steps} name={'parallel'} value={step.parallel._id|| ''} label={'المرحلة الموازية'} handleSelect={this.handleSelect} />
                  </Grid>
                  <Grid item xs>
                    <StepSelect steps={steps} name={'onFailure'} value={step.onFailure._id|| ''} label={'عند الفشل نعود لمرحلة'} handleSelect={this.handleSelect} />
                  </Grid>
                  <Grid item xs>
                    <Button variant="contained" size="large" color="primary" fullWidth className={classes.button}
                      onClick={this.handleAddEditStep}
                      disabled= {step.name.name === "--"}
                    >
                      {(step._id === "") ? "إضافة":"تعديل"}
                    </Button>
                  </Grid>
                  </>
                }
                <Grid item xs={12}>
                  <Typography align="right">
                    <Button variant="text" size="large" color="primary"  className={classes.button}
                      onClick={this.handleCancel}
                    > إلغاء التعديلات </Button>
                    <Button variant="contained" size="large" color="primary"  className={classes.button}
                      onClick={ (match.params.id ) ? this.handleEditProcess: this.handleAddProcess}
                      disabled= { (processSteps && processSteps.length === 0) || name === ""}
                    > حفظ التعديلات </Button>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Layout>
      </Fragment>
    );
  }
}

export default withStyles(styles)(withRoot(Processes));

