import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class AlertDialog extends React.Component {
  state = {
    open: false,
  };

  render() {
    const { handleClose, handleConfirmation, open, title, desc } = this.props
    return (
        <Dialog
          open={this.state.open||open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-title">{title ||'تأكيد الإجراء'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            { desc || "يرجى تأكيد هذا الإجراء"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
            إلغاء
            </Button>
            <Button onClick={handleConfirmation} color="primary" autoFocus>
            موافق
            </Button>
          </DialogActions>
        </Dialog>
    );
  }
}

export default AlertDialog;