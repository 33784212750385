import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Divider, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import ReportsIcon from '@material-ui/icons/ImportContacts';
import { withStyles } from '@material-ui/core/styles';
import withRoot from '../withRoot';
import { withRouter } from 'react-router'

const styles = theme => ({
	toolbar: theme.mixins.toolbar,
});

const drawerWidth = 240;

class MenuDrawer extends Component {

	link = (goTo) => {
		let {history } = this.props;
		history.push( goTo );
	}

	render() {
		let { classes, activeItem } = this.props;
		return (
			<div>
				<div className={classes.toolbar} />
				<Divider />
				<List>
					<ListItem button selected={"/" === activeItem } onClick={() => this.link('/')}>
						<ListItemIcon><ReportsIcon /></ListItemIcon>
						<ListItemText primary={'الرئيسية'} />  
					</ListItem>
				</List>
			</div>
		)
	}
}


MenuDrawer.propTypes = {
	classes: PropTypes.object.isRequired,
	// Injected by the documentation to work in an iframe.
	// You won't need it on your project.
	container: PropTypes.object,
	theme: PropTypes.object.isRequired,
};

MenuDrawer = withRoot(withStyles(styles, { withTheme: true })(withRouter(MenuDrawer)));

export {
	drawerWidth,
	MenuDrawer
}

