import React, { Component, Fragment } from 'react';
import Layout from '../components/layout';
import withRoot from '../withRoot';
import ContentHeader from '../components/ContentHeader';
import ProcessesManagement from '../components/ProcessesManagement';
import client from '../utils/Api'
import AlertDialog from '../components/AlertDialog';


const rows = [
	{ id: 'name', numeric: false, disablePadding: true, label: 'اسم المحتوى' },
	{ id: 'level', numeric: false, disablePadding: false, label: 'المستوى' },
	{ id: 'allSteps', numeric: false, disablePadding: false, label: 'مراحل الإعداد' },
	// { id: 'currentStep', numeric: false, disablePadding: false, label: 'المرحلة الحالية' },
];

const contentsService = client.service('contents');


class Contents extends Component {

	// initialize our state 
	state = {
		isLoading: true,
		contents: [],
		checkedItems: [],
		openAlertDialog: false,
	}

	loadContents() {
		Promise.all([
			contentsService.find()
		]).then( ([contents]) => {
			let _contents = contents.data
			if (contents.code !== 404  ) {
				if (_contents.length !== 0) {
					_contents.map( process => {
						if (process.process) {
							process.allSteps = process.process.steps.map( s => s.name.name).join(',')
						}
						return process;
					});
				} else {
					window.location='/contents/add'
				}
			}

			this.setState({ contents: _contents,  isLoading: false });
		}).catch( err => {client.logout();});
	}

	async componentWillMount() {
		this.loadContents();
	}

	handleModalClose = () => {
		this.setState({ openAlertDialog: false })
	}

	handleModalConfirmation = () => {
		this.setState({ openAlertDialog: false })
		const {checkedItems} = this.state
		return checkedItems.map( id => {
			contentsService.remove(id, { query: {} })
			.then((responseJson) => {
				if ( responseJson.name === "NotFound" ) 
					console.log ( responseJson.message)
				else {
					this.loadContents()
				}
			})
			return null;
		})	
	}

	handleDelete = (e,_ids) =>	{
		this.setState({ openAlertDialog: true, checkedItems: _ids })		
	}

	handleEdit = (e,_id) =>	{
		window.location='/contents/'+_id;
	}

	render() {
		const {contents, openAlertDialog, isLoading} = this.state;
		return (
			<Fragment>
				<Layout
					activeTab="1"
					title={'محتوى زيدو'}
					footerText={'جميع الحقوق محفوظة لمجموعة عالم زيدو 2019'}
					isLoading= {isLoading}
				>
					<ContentHeader
						title=' قـائمة وحـدات المحتوى'
						desc='قائمة بكل أقسام المحتوى الذي يتم إعداده للمراحل المختلفة بعالم زيدو '
						showBackLink ={false}
					/>
					<AlertDialog 
						open={openAlertDialog} 
						handleClose= {this.handleModalClose} 
						handleConfirmation={this.handleModalConfirmation}
					/>
					{ 	contents.length !==0 &&
						<ProcessesManagement 
							processes={contents}
							rows={rows}
							name={' مراحل عملية' }
							handleAdd= {() => window.location='/contents/add'}
							handleEdit= {this.handleEdit}
							handleDelete= {this.handleDelete}
							handleDetails={(e,id) => window.location='/details/'+id}
						/>
					}
				</Layout>
			</Fragment>
		);
	}
}

export default withRoot(Contents);

