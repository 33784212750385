import React, { Component, Fragment } from 'react';
import Layout from '../components/layout';
import { Button, Typography,  } from '@material-ui/core';
import ContentHeader from '../components/ContentHeader';
import ProcessesManagement from '../components/ProcessesManagement';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import StepSelect from '../components/StepSelect';
import { withStyles } from '@material-ui/core/styles';
import client from '../utils/Api'


const rows = [
	{ id: 'name', numeric: false, disablePadding: true, label: 'المراحل' },
	{ id: 'order', numeric: false, disablePadding: false, label:'ترتيب المرحلة'},
	{ id: 'parallel', numeric: false, disablePadding: false, label: 'المراحل الموازية' },
	{ id: 'onFailure', numeric: false, disablePadding: false, label: 'في حالة فشل نذهب لمرحلة' },
];

const Levels = [
	{_id: 'level1' , name: 'الاول'},
	{_id: 'level2' , name: 'الثاني'},
	{_id: 'level3' , name: 'الثالث'},
]

const styles = theme => ({
	textField: {
		padding: theme.spacing.unit ,
		marginTop: theme.spacing.unit * 1,
	},
	button: {
	  marginTop: theme.spacing.unit * 2,
	  padding: theme.spacing.unit * 2,
	},
});
  
const processesService = client.service('processes');
const contentsService = client.service('contents');
const stepsService = client.service('steps');

class ContentsAdd extends Component {

	// initialize our state 
	state = {
		contents: [],
		checkedItems: [],
		level : "level1",
		processes: [],
		selectedProcess:{ _id: ' ', name:" ", steps : []},
		name : "",
		isLoading: true,
		addStep:false,
		selectedStep: {
			_id:'',
			name: { _id:'', name: '--'},
			parallel: { _id:'', name: '--'},
			order: { _id:'', name: ''},
			onFailure: { _id:'', name: '--'},  
		}
	}
	loadTemplates() {
		// Get all processes
		Promise.all([
			processesService.find(),
		]).then( ([responseJson]) => {
			let processes = []
			processes = responseJson.data.map( process => {
				process.process = process.name
				if (typeof process.steps !== undefined) {
					const steps = process.steps;
					process.allSteps = steps.map( step =>step.name.name).join(', ') 
				}
				return process
			});
	
			// update the state
			this.setState({ processes: processes,  isLoading: false});
		});
	}
	loadContent(id) {
    
		Promise.all([
			contentsService.get(id, {})
		]).then( ([content]) => {
			if (content.code !== 500)
				this.setState({ 
					_id: content._id,
					name: content.name,
					level: content.level,
					selectedProcess: content.process
				});
		});
			 
	}
	loadSteps() {
		// Get all steps
		Promise.all([
			stepsService.find(),
		]).then( ([responseJson]) => {
			console.log(responseJson)
			if (responseJson.code !== 500) {
 				this.setState({ steps: responseJson });
			}
		});
	}

	async componentWillMount() {
		this.loadTemplates();
		this.loadSteps();
	}
	
	componentDidMount() {
		const {match} = this.props
		if(match.params.id !== undefined)
		  this.loadContent(match.params.id)
	}

	handleSelectLevel = item => event => {
 		this.setState({
		  level: event.target.value,
		});
	  };
	  
	handleSelectProcess = name => event => {
		const { processes} = this.state
		const value = event.target.value
		if (value !== "") {
			const selectedProcess = processes.filter( (process) => process._id === value).shift();
			// debugger
			this.setState({
				selectedProcess: selectedProcess,
			});	
		}
	  };
	
	handleAddContent = () => {
		const {name, level, selectedProcess} = this.state
		
		Promise.all([
			contentsService.create({
				name: name,
				level: level,
				process: selectedProcess
			} , {})
		]).then( ([content]) => {
		console.log("content added ");
		window.location='/contents/';
		});
	};
	
	handleEditContent = () => {
		const { match } = this.props;
		const {name, level, selectedProcess} = this.state
		
		Promise.all([
			contentsService.update( match.params.id, {
				name: name,
				level: level,
				process: selectedProcess
			})
		]).then( ([content]) => {
			console.log("content added ");
			window.location='/contents/';
		});
	}

	handleDelete = (e,_id) =>	{
		let { selectedProcess } = this.state;
		const _steps =selectedProcess.steps.filter(sp => sp._id !== _id.shift())
		this.setState({
			selectedProcess : {...selectedProcess, steps:_steps},
		}); 
	}
	handleEdit = (e,_id) =>	{
		let { selectedProcess } = this.state;
		
		this.setState({
			  selectedStep : selectedProcess.steps[_id],
			  addStep:true
		}); 
	}
	handleCancel = (e,_id) =>	{
		window.location='/contents/';
	}

	handleEditProcess = () => {
		const id = this.state.selectedProcess._id
		window.location='/processes/' + id;
	}

	handleChange = name => event => {
		this.setState({
		  [name]: event.target.value,
		});
	};

	handleSelect = item => event => {
		const {steps, selectedStep} = this.state
		let _step = { _id:'', name: '--'}
		if (event.target.value !== "")
	      _step = steps.find(s => s._id === event.target.value) 
	
		  console.log('_step',item)
		this.setState({
			selectedStep: {...selectedStep, [item]: _step ,}
		});
	}
	handleAddEditStep = () =>{
 		let {  selectedStep, selectedProcess } = this.state;
		if (selectedStep._id === ""){
			selectedStep._id = selectedProcess.steps.length 
			selectedProcess.steps.push(selectedStep)
		}
		else {
			selectedProcess.steps[selectedStep._id] = selectedStep
		}
		this.setState({
			selectedProcess: selectedProcess,
		  	selectedStep : {
				_id:'',
				name: { _id:'', name: '--'},
				parallel: { _id:'', name: '--'},
				order: { _id:'', name: '--'},
				onFailure: { _id:'', name: '--'},  
		  	}
		}); 
	  };

	render() {
		const { classes, match } = this.props;
		const { name, level, processes, selectedProcess, isLoading, steps,selectedStep, addStep } = this.state;

		return (
			<Fragment>
				<Layout
					activeTab="1"
					title={'محتوى زيدو'}
					footerText={'جميع الحقوق محفوظة لمجموعة عالم زيدو 2019'}
					isLoading= {isLoading}
				>
					<ContentHeader
						title={'إنشاء وحدة محتوى جديدة'}
						desc={'لبدأ وحدة محتوى جديدة واختيار عملية مراحل الإعداد المناسبة لها'}
						backLink ='/contents/'
					/>

					<Grid container  spacing={16}>
						<Grid item xs>
							<TextField
								id="filled-name"
								label="اسم وحدة المحتوى"
								className={classes.textField}
								value={name}
								onChange={this.handleChange('name')}
								margin="normal"
								variant="filled"
								fullWidth
							/>
						</Grid>
						<Grid item xs>
							<StepSelect steps={Levels} name={'name'}  value={level} label={'المستوى'}  handleSelect={this.handleSelectLevel} />
						</Grid>
						<Grid item xs>
							<StepSelect steps={processes} name={'_id'}  value={selectedProcess._id} label={'اختيار عملية الإعداد المناسبة'}  handleSelect={this.handleSelectProcess} />
						</Grid>
						<Grid item xs>
							<Button variant="contained" size="large" color="primary"  className={classes.button}
								onClick={() => window.location='/processes/add' }
								> إنشاء عملية إعداد جديدة</Button>
 						</Grid>
						 <Grid item xs={12}>
							<ProcessesManagement 
								processes={selectedProcess.steps}
								rows={rows}
								steps={steps}
								step={selectedStep}
								name={' مراحل عملية' +name}
								addStep={addStep}
								classes={classes}
								handleAdd= {() => this.setState({ addStep: true})}
								handleEdit= {this.handleEdit}
								handleDelete= {this.handleDelete}
								handleSelect={this.handleSelect}
								handleAddEditStep={this.handleAddEditStep}
							/>
 						</Grid>
						 <Grid item xs={12}>
							<Typography align="right">
								<Button variant="text" size="large" color="primary"  className={classes.button}
								onClick={this.handleCancel}
								> إلغاء الإجراءات </Button>
								<Button variant="contained" size="large" color="primary"  className={classes.button}
								onClick={ (match.params.id !== undefined) ? this.handleEditContent: this.handleAddContent}
								disabled= { name === "" || level === "" || selectedProcess._id === ""}
								> إنشاء الوحدة</Button>
							</Typography>
						</Grid>
					</Grid>
					
				</Layout>
			</Fragment>
		);
	}
}

export default withStyles(styles)(ContentsAdd);

