import React, { Component, Fragment } from 'react';
import Layout from '../components/layout';
import ContentHeader from '../components/ContentHeader';
import ProcessTable from '../components/ProcessTable';
import DetailsDialog from '../components/DetailsDialog';
import NotificationDialog from '../components/NotificationDialog';
import withRoot from '../withRoot';
import client from '../utils/Api'

const rows = [
	{ id: 'name', numeric: false, disablePadding: true, label: 'المراحل' },
	{ id: 'assign', numeric: false, disablePadding: false, label: 'المسؤل عنها' },
	{ id: 'status', numeric: false, disablePadding: false, label: 'الحالة' },
    { id: 'duration', numeric: false, disablePadding: false, label: 'الوقت المقدر لها' },
    { id: 'datestart', numeric: false, disablePadding: false, label: 'تاريخ البداية' },
    { id: 'dateend', numeric: false, disablePadding: false, label: 'تاريخ الانتهاء' },
    { id: 'notes', numeric: false, disablePadding: false, label: 'ملاحظات'}
];

// TODO create add assign to DB

const durations = [
    { _id: 0,  name: ' -- '},
    { _id: 1,  name: '5 يوم'},
    { _id: 2,  name: '10 يوم'},
    { _id: 3,  name: '15 يوم'},
    { _id: 4,  name: '20 يوم'},
]

const STATUS = {
    PENDING : 'pending',
    IN_PROGRESS: 'in_progress',
    CLOSED:'closed',
    BLOCKED : 'blocked',
    DONE: 'done',
    FAILED: 'failed' 
}

const contentsService = client.service('contents');
const usersService = client.service('users');

class ContentDetails extends Component {

    // initialize  state 
	state = {
		isLoading: true,
        process: {steps: []},
        name: '',
        level:'',
        startDate: '2019-01-01',
        checkedItems: "",
        openDetailsDialog : false,
        openNotificationDialog : false,
        assigns: [
            { _id: 0,  name: ' -- ', roles: ' -- '},
        ]
    }
    
    loadDetails() {
        const {match} = this.props
        if (match.params.id !== "") {
            const promiseContent = new Promise((resolve, reject) => {
                resolve( contentsService.get( match.params.id , {}));
            });

            return promiseContent;
        }
    }

    loadUsersAssigns() {
        const users = new Promise((resolve, reject) => {
            resolve( usersService.find({}));
        });

        users.then( (users) => {
            users.data.map((user)=>{
                user.name = user.name || user.email
                user.roles = user.permissions
                return user
            })
            this.setState ({
                assigns: [{ _id: 0,  name: ' -- ', roles: ' -- '}, ...users.data]
            })
        });
        
    }

    addContentDetails = (id, steps) => {
		console.log('addContentDetails')
    }

    updateContentDetails = (id) => {
        console.log('updateContentDetails',id, this.state)
        const { name, level,process} = this.state
        contentsService.update(id, { name:name, level:level, process: process}, {}).then((responseJson) => {
            console.log("updateContentDetails done ", responseJson)
            this.setState ({
                openDetailsDialog: false
            })
        })
    }

    loadDialogDetails() {
        const {name, level, process, checkedItems, assigns} = this.state;
        
        let selectedStep = process.steps.filter(s => s._id === checkedItems).shift()
        selectedStep.assign = !selectedStep.assign ? assigns[0]:selectedStep.assign
        selectedStep.duration = !selectedStep.duration? durations[0]:selectedStep.duration
        selectedStep.role = selectedStep.name.role || " -- "
        
        return {
            level: level ,
            name:name,
            _id:      selectedStep._id  ,
            assign:   selectedStep.assign._id , 
            status:   selectedStep.status   || STATUS.PENDING, 
            duration: selectedStep.duration._id, 
            datestart:selectedStep.datestart|| new Date(), 
            dateend:  selectedStep.dateend  || new Date(), 
            notes:    selectedStep.notes    || ' ',
            docIn:    selectedStep.docIn    || ' ',
            docOut:   selectedStep.docOut   || ' ',
            stepRole: selectedStep.role
        }
    }

    handleOpenDetailsDialog = (e,_ids) => {

        const {checkedItems} = this.state
        this.setState({ 
            openDetailsDialog: !this.state.openDetailsDialog,
            checkedItems: checkedItems === _ids || _ids === undefined ? "":_ids[0]
        })
    }

    handleContentDetails = ( details) => {
        
        const {_id, process, checkedItems, assigns} = this.state;

        let _process = process;
        let _details = details
        _details.assign = assigns.filter( u => u._id === details.assign).shift()
        _details.duration = durations[details.duration]

        let newSteps = process.steps.map(c => {
            if (c._id === checkedItems) {
                c = {...c, ..._details }
            }
            return c
        });
        _process.steps = newSteps;
        this.setState({
            process:  _process,
            openDetailsDialog : false
        }, this.updateContentDetails(_id));        
    }

    handleStartStep = (stepId) => {
        console.log('handleStartStep',stepId, this.state)
        const { _id,name, level,process} = this.state
        let _process = process
        _process.steps[stepId].status = STATUS.IN_PROGRESS 
        contentsService.update(_id, { name:name, level:level, process: process}, {}).then((responseJson) => {
            console.log("handleStartStep done ", responseJson)
            this.setState ({
                openDetailsDialog: false
            })
        })
    }

    handleFailureStep = (stepId) => {
        console.log('handleStartStep',stepId, this.state)
        const { _id,name, level,process} = this.state
        let _process = process
        _process.steps[stepId].status = STATUS.FAILED 
        contentsService.update(_id, { name:name, level:level, process: process}, {}).then((responseJson) => {
            console.log("handleStartStep done ", responseJson)
            this.setState ({
                openDetailsDialog: false
            })
        })
    }

    handleSuccessStep = (stepId) => {
        console.log('handleStartStep',stepId, this.state)
        const { _id,name, level,process} = this.state
        let _process = process
        _process.steps[stepId].status = STATUS.DONE 
        contentsService.update(_id, { name:name, level:level, process: process}, {}).then((responseJson) => {
            console.log("handleStartStep done ", responseJson)
            this.setState ({
                openDetailsDialog: false
            })
        })
    }

    
    handleOpenNotificationDialog = (e,_id) =>	{
        this.setState({ openNotificationDialog: !this.state.openNotificationDialog });
    }

    componentWillMount() {
        const promiseContent = this.loadDetails();
        this.loadUsersAssigns();
        promiseContent.then( (content) => {
            console.log('content',content);
            if (content.code !== 500) {
                const { _id, name, level, process} = content
                /// process steps
                const ps = process.steps.map( (s, i) => {
                    s._id = i
                    return s
                })
                
                this.setState({
                    _id:_id,
                    name:name,
                    level:level,
                    process: {...process, steps:ps},
                    isLoading: false
                });
            }
        });
    }


    render() {
            const {name, level, process, startDate, openDetailsDialog, openNotificationDialog, isLoading, assigns} = this.state;

            return (
                <Fragment>
                    <Layout
                        activeTab="1"
                        title={'محتوى زيدو'}
                        footerText={'جميع الحقوق محفوظة لمجموعة عالم زيدو 2019'}
                        isLoading= {isLoading}
                    >
                        <ContentHeader
                            title={` تفاصيل ${name}`}
                            desc={`  المستوى ${level} |   تم البدأ في ${startDate}  |  عدد مراحل عملية الإعداد  ${ process.steps.length} `}
                            backLink ='/contents/'
                        />

                        {   openDetailsDialog && 
                            <DetailsDialog
                                assigns= {assigns}
                                durations={durations}
                                open={openDetailsDialog}
                                {...this.loadDialogDetails()}
                                handleClose={ this.handleOpenDetailsDialog}
                                handleAdd={ this.handleContentDetails}
                                handleStart={this.handleStartStep}
                                handleSuccess={this.handleSuccessStep}
                                handleFailure={this.handleFailureStep}
                            />
                        }
                        
                        {   openNotificationDialog && 
                            <NotificationDialog 
                                open={openNotificationDialog}
                            />
                        }
                        
                        { 	process.steps.length !==0 && 
                            <ProcessTable 
                            processes={process.steps} 
                            rows={rows} 
                            tableTitle={`تفاصيل إعداد ${name}`}
                            actions= {{
                                handleDetails: this.handleOpenDetailsDialog,
                                handleNotify:  this.handleOpenNotificationDialog
                            }}
                            />
                        }
                    </Layout>
                </Fragment>
            );
    }

}

export default withRoot(ContentDetails);
