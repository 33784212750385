// import io from 'socket.io-client';
import feathers from '@feathersjs/client';
import Config from '../config'
import rest from '@feathersjs/rest-client';

// const socket = io(Config.appApiUrl);
const restClient = rest(Config.appApiUrl)


const client = feathers();
console.log(Config)
// client.configure(feathers.socketio(socket));
client.configure(restClient.fetch(window.fetch));

client.configure(feathers.authentication({
  storage: window.localStorage
}));

export default client;