import React from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { Button, Typography, } from '@material-ui/core';
import StepSelect from '../components/StepSelect';
import 'date-fns';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import { withStyles } from '@material-ui/core/styles';
import AlertDialog from '../components/AlertDialog';


const styles = theme => ({
    successBtn: {
      backgroundColor: '#2BCCA8',
    },
    failBtn: {
        backgroundColor: '#F55046',
        margin: theme.spacing.unit * 2,
    },
  })
const STATUS = {
    PENDING : 'pending',
    IN_PROGRESS: 'in_progress',
    CLOSED:'closed',
    BLOCKED : 'blocked',
    DONE: 'done',
    FAILED: 'failed'
}
class FormDialog extends React.Component {
  state = {
    _id : this.props._id,
    open: this.props.open,
    assign : this.props.assign,
    duration : this.props.duration,
    docIn : this.props.docIn,
    docOut  : this.props.docOut,
    notes : this.props.notes,
    status: this.props.status,
    datestart:this.props.datestart,
    dateend:this.props.dateend,
    openAlertDialog: false,
    alertConfirmationCB: () => {},
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  handleSelect = item => event => {
      console.log(item)
      const value = event.target.value
      this.setState({
        [item]: value,
     });
  }
  handleChange = name => event => {
    
		this.setState({
		  [name]: event.target.value,
		});
  };
  
  handleDateChange = name => date => {
		this.setState({
		  [name]: date.toLocaleDateString(),
		});
	};
  
  // handleSuccess = () => {
  //   this.setState({ status: STATUS.DONE });
  //   this.handleClose()
  // }
  // handleFailure = () =>  {
  //   this.setState({ status: STATUS.FAILED });
  //   this.handleClose()
  // }
  handleAlertClose = () => {
    this.setState({ openAlertDialog: false })
  }

  handleAlertOpen = (callBack) => {
    this.setState({
      openAlertDialog: true,
      alertConfirmationCB: callBack
    })
  }

  handleAlertConfirmation = ()=> {
    const {alertConfirmationCB} = this.state
    console.log( alertConfirmationCB)
    alertConfirmationCB();
    this.setState({ openAlertDialog: false })
    // this.handleSuccessStep(stepId)
  }
  
  render() {
    const {level, name , assigns, durations, classes, handleClose, handleAdd,
      handleStart, handleSuccess, handleFailure, stepRole} = this.props
    const { assign , docIn, docOut, duration, notes, status, datestart, dateend, _id, openAlertDialog} = this.state
    const assignsByRole = assigns.filter(user =>  user.roles.indexOf(stepRole) !== -1)
    return (
        <Dialog
          open={this.state.open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          // maxWidth='md'
          fullScreen
          scroll="body"
        >
          <DialogTitle id="form-dialog-title"> تفاصيل {name} 
          </DialogTitle>
          <DialogContent>
            <AlertDialog 
              open={openAlertDialog} 
              handleClose= {this.handleAlertClose} 
              handleConfirmation={this.handleAlertConfirmation}
            />
            <DialogContentText>
                {assign} | المستوى {level}  | الحالة - {status} 
            </DialogContentText>
            <Typography align="right">
              { status === STATUS.PENDING && <Button variant="contained" size="large" color="primary" onClick={ () => handleStart(_id) } > البدأ بالمرحلة</Button>}
            </Typography>
            <Typography align="left">
              { status !== STATUS.PENDING && <Button variant="contained" size="large" color="primary" className={classes.successBtn}  onClick={ () => this.handleAlertOpen(() => handleSuccess(_id)) } > نجاح المرحلة</Button>}
              { status !== STATUS.PENDING && <Button variant="contained" size="large" color="primary" className={classes.failBtn} onClick={ () => this.handleAlertOpen(() => handleFailure(_id)) } > فشل المرحلة </Button>}
            </Typography>
            <Grid container  spacing={16}>
                <Grid item xs={12}>
                    <StepSelect steps={assignsByRole} name={'assign'}  value={assign} label={'اسم المسؤل عن المرحلة'}  handleSelect={this.handleSelect} />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="docIn"
                        label='رابط المستندات المدخلة'
                        value={ docIn }
                       onChange={this.handleChange('docIn')}
                        margin="normal"
                        variant="filled"
                        fullWidth
                    />
                </Grid> 
                <Grid item xs={6}>
                    <TextField
                        id="docOut"
                        label='رابط المستندات الناتجة عن المرحلة'
                        value={docOut}
                       onChange={this.handleChange('docOut')}
                        margin="normal"
                        variant="filled"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <StepSelect steps={durations} name={'duration'}  value={duration} label={'الوقت المقدر للمرحلة'}  handleSelect={this.handleSelect} />
                </Grid>
                <Grid item xs={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justify="space-around"  spacing={16}>
                        <Grid item xs>
                            <DatePicker
                                margin="normal"
                                label='تاريخ البدا بالمرحلة'
                                value={datestart}
                                variant="filled"
                                onChange={this.handleDateChange('datestart')}
                                
                            />
                        </Grid>
                        <Grid item xs>
                            <DatePicker
                                margin="normal"
                                label="تاريخ الانتهاء من المرحلة"
                                value={dateend}
                                variant="filled"
                                onChange={this.handleDateChange('dateend')}
                            />
                        </Grid>
                        </Grid>
                    </MuiPickersUtilsProvider>  
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="notes"
                        value={notes}
                        label={'ملاحظات'}
                       onChange={this.handleChange('notes')}
                        margin="normal"
                        variant="filled"
                        fullWidth
                        multiline
                        rowsMax="4"
                    />
                </Grid>                     
            </Grid>

          </DialogContent>
          <DialogActions>
            <Grid item xs={12}>
                <Typography align="right">
                    <Button variant="text" size="large" color="primary"  onClick={handleClose}
                    > إلغاء التعديلات </Button>
                    <Button variant="contained" size="large" color="primary"  
                    onClick={ () => handleAdd(this.state) }
                    > حفظ التعديلات </Button>
                </Typography>
            </Grid>
          </DialogActions>
        </Dialog>
    );
  }
}

export default withStyles(styles)(FormDialog)