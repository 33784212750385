import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import { Link, Grid } from '@material-ui/core';

class ContentHeader extends Component {

  render() {
    const { title, desc, showBackLink = true, backLink ='/' } = this.props;
    return <Grid container >
    <Grid item xs={10}>
        <Typography component="h2"  variant="h6" gutterBottom>
          {title}
        </Typography>
    </Grid>
    <Grid item xs={2} >
      { showBackLink &&
      <Typography component="h2" variant="subtitle1"  align="right">
          <Link href={backLink} color="inherit" >
            الرجوع للقائمة >
          </Link>
      </Typography>
      }
    </Grid>
    <Grid item xs={12} >
    <Typography component="h2" variant="subtitle1">
        {desc}
      </Typography>
    </Grid>
  </Grid>;
  }
}

export default ContentHeader;
