import React, { Component } from 'react';
import { Switch, Route, Redirect} from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import { 
  SignIn,
  Home,
  Processes,
  Contents,
  ContentsAdd,
  ContentDetails,
} from './pages';
import Helper from './utils/Helper'

const Routes = () => (
    <Switch>
      <Route exact path='/login' component={SignIn}/>
      <PrivateRoute exact path='/' component={Contents} />
      <PrivateRoute exact path='/processes' component={Home} />
      <PrivateRoute exact path='/processes/add' component={Processes} />
      <PrivateRoute exact path='/processes/:id?' component={Processes} />
      <PrivateRoute exact path='/contents/add' component={ContentsAdd} />
      <PrivateRoute exact path='/contents/' component={Contents} />
      <PrivateRoute exact path='/contents/:id' component={ContentsAdd} />
      <PrivateRoute exact path='/details/:id?' component={ContentDetails} />
      {/* <Route component={NotFound} /> */}
    </Switch>
)

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
     Helper.isAuthenticated() ? (
      <Component {...props}/>
    ) : (
      <Redirect to={{
        pathname: '/login',
        state: { from: props.location }
      }}/>
    )
  )}/>
)



class Routing extends Component {
  render() {
    return (
      <BrowserRouter>
        <Routes/>
      </BrowserRouter>
    );
  }
}

export default Routing;
